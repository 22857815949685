export default function Divider(props) {
  return (
    <>
      <div
        className={
          props.bg + " relative flex py-7 items-center px-6 md:px-10 lg:px-16"
        }
      >
        {/* <div className="flex-grow border-t border-gray-400"></div> */}
        <span className="flex-shrink mr-2 text-gray-900 ">
          <h1 className="text-lg font-semibold">{props.text}</h1>
        </span>
        <div className="flex-grow border-t border-gray-400"></div>
      </div>
    </>
  );
}
