import React, { Suspense } from "react";

import Divider from "./components/Divider";
import Header from "./components/Header";
const Work = React.lazy(() => import("./sections/Work"));
const Footer = React.lazy(() => import("./sections/Footer"));
export default function App() {
  return (
    <>
      <div className="px-6 md:px-10 lg:px-20 dark:bg-slate-900 dark:text-white text-gray-900 font-Poppins">
        <Header className=""></Header>
      </div>
      <Divider text="Works" bg="bg-slate-100"></Divider>
      <Suspense
        fallback={
          <div className="min-h-screen bg-slate-100 items-center">
            <span>Loading</span>
          </div>
        }
      >
        <div
          className="bg-slate-100 md:py-3 px-6 md:px-10 lg:px-20 font-Poppins"
          id="work"
        >
          <Work></Work>
        </div>
      </Suspense>

      <Suspense
        fallback={
          <div className="min-h-[80vh] bg-slate-100 flex items-center">
            <span>Loading</span>
          </div>
        }
      >
        <Footer></Footer>
      </Suspense>
    </>
  );
}
