import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Typewriter from "typewriter-effect";

export default function Header(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [menus, setMenus] = useState([]);
  const loc = useLocation();

  useEffect(() => {
    async function getMenu() {
      const req = await fetch("/landing/data/menus.json");
      const response = await req.json();
      setMenus(response);
    }

    getMenu();
    const scrollFun = () => {
      if (window.pageYOffset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const body = document.querySelector("body");
    if (navbarOpen) {
      body.classList.remove("overflow-auto");
      body.classList.add("overflow-hidden");
    } else {
      body.classList.add("overflow-auto");
      body.classList.remove("overflow-hidden");
    }
    window.addEventListener("scroll", scrollFun);

    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [navbarOpen, scrolled]);
  return (
    <>
      <header
        className={
          (scrolled ? " h-16 md:h-20 bg-white shadow-md " : " h-20 md:h-24 ") +
          "fixed left-0 right-0 flex justify-between items-center py-6 px-6 md:px-12 transition-all duration-200 z-50 dark:bg-slate-900 dark:text-white text-gray-900"
        }
      >
        <a
          href="/#"
          className="flex justify-center items-center space-x-2"
          aria-label="brand"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-blue-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
            />
          </svg>
          <h1 className="font-bold text-xl ">Nurhadi</h1>
        </a>
        <nav className="lg:flex lg:flex-row">
          <button
            aria-label="Menu Toggler"
            className="md:hidden p-2"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <div className="">
              <span
                className={
                  "bg-blue-700 w-7 h-[0.15rem] block transform transition duration-500 ease-in-out" +
                  (navbarOpen ? " rotate-45 translate-y-1.5" : "")
                }
              ></span>
              <span
                className={
                  "bg-blue-700 w-5 h-[0.15rem] block my-1 transform transition duration-500 ease-in-out" +
                  (navbarOpen && " opacity-0")
                }
              ></span>
              <span
                className={
                  "bg-blue-700 w-7 h-[0.15rem] block transform transition duration-500 ease-in-out" +
                  (navbarOpen ? " -rotate-45 -translate-y-1.5" : "")
                }
              ></span>
            </div>
          </button>

          {console.log(loc)}
          <ul
            id="menu"
            onClick={() => setNavbarOpen(false)}
            className={
              (navbarOpen ? " translate-x-0 " : "  -translate-x-full ") +
              (scrolled ? " top-15 pt-5 " : " top-20 ") +
              " min-h-screen  px-5  fixed left-0 right-0  md:top-0 transform transition duration-500  md:relative md:flex md:min-h-0 md:space-y-0 md:space-x-6 md:p-0 text-lg  z-50 md:translate-x-0 bg-white lg:bg-inherit "
            }
          >
            {menus.map((menu) => {
              return menu.id === "CTA" ? (
                <div key={menu.id}>
                  <li className="items-center flex bg-orange-600 rounded-lg">
                    <a
                      className="md:py-3 py-1.5 px-2 flex gap-1 text-white font-semibold"
                      href={menu.path}
                    >
                      <svg className="h-6 w-6" viewBox="0 0 24 24">
                        <path fill="currentColor" d={menu.icon} />
                      </svg>{" "}
                      {menu.text}
                    </a>
                  </li>
                  <span className="hidden md:flex h-3 w-3 relative bottom-[98%] left-[98%]">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-300 opacity-75"></span>
                    <span className=" inline-flex rounded-full h-3 w-3 bg-orange-600"></span>
                  </span>
                </div>
              ) : (
                <li key={menu.id} className="items-center flex ">
                  <a
                    className={
                      ((menu.path === "/" + loc.hash ||
                        (menu.text.toLowerCase() === "home" &&
                          loc.hash === "")) &&
                        " text-blue-600 ") + " md:py-3 py-1.5 px-2 flex gap-1 "
                    }
                    href={menu.path}
                  >
                    <svg className="h-6 w-6" viewBox="0 0 24 24">
                      <path fill="currentColor" d={menu.icon} />
                    </svg>{" "}
                    {menu.text}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </header>

      <section
        id="home"
        className="pt-20 pb-5 lg:pb-10  min-h-screen  md:flex gap-10 space-y-10 md:space-y-0 justify-between"
      >
        <div className="group flex-1 flex items-center md:order-1 bg-gradient-to-r relative min-h-[34vh] md:min-h-[50vh]">
          <div className="group-hover:-skew-y-0 duration-500 absolute top-[12.5%] w-full -z-10 h-3/4 md:h-[60%] rounded-lg bg-gradient-to-bl from-blue-100 to-blue-500 skew-y-1 "></div>
          <img
            src="/landing/images/profile.svg"
            alt="Profile"
            className="w-[60%] h-auto md:max-w-xs block mx-auto group-hover:md:-translate-y-4 duration-500 "
          />
        </div>
        <div className="flex flex-1 items-center content-between">
          <div className="items-center">
            <h1
              id="typewrite"
              className="text-2xl font-bold md:text-4xl leading-tight"
            >
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                }}
                onInit={(typewriter) => {
                  var text = [
                    "Hello There.. !!",
                    "I am Nurhadi",
                    "Web Developer",
                  ];
                  typewriter
                    .pauseFor(500)
                    .typeString(text[0])
                    .pauseFor(300)
                    .deleteChars(text[0].length)
                    .pauseFor(200)
                    .typeString(text[1])
                    .pauseFor(1500)
                    .deleteChars(text[1].length - 5)
                    .pauseFor(300)
                    .typeString(text[2])
                    .pauseFor(1500)

                    .start();
                }}
              />
            </h1>
            <h2 className="mt-4 text-lg font-semibold">
              A Functional ERP SAP PP and profesional web developer from
              Jakarta, Indonesia
            </h2>
            <p
              className="text-lg italic"
              x-intersect="$el.classList.add('fadeInUp')"
            >
              " I always give the best for every projects I did. I give solution
              for your needs"
            </p>
            <div className="mt-10">
              <a
                href="https://linkedin.com/in/nurhadi"
                className="group  md:inline-flex justify-center gap-x-3  rounded-md shadow-sm bg-gradient-to-r from-blue-400 to-blue-600 text-white py-3 px-10 flex flex-row "
              >
                <span>Hire Me</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 group-hover:translate-x-4 duration-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
